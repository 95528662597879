<template>
  <div class="login-page">
    <div class="container">
      <div class="login-form">
        <div class="main-div">
          <div class="panel">
            <h2>Admin</h2>
          </div>
          <div class="form-group">
            <input type="text" v-model="form.username" class="form-control" placeholder="Usuario">
          </div>
          <div class="form-group">
            <input type="password" v-model="form.password" class="form-control" placeholder="Contraseña">
          </div>
          <!--
            <div class="forgot">
              <a href="reset.html">Forgot password?</a>
            </div>
          -->
          <button type="button" class="btn btn-submit" @click="onSubmit()">INICIAR SEISIÓN</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoginPage",
  data:() => ({
    loadForm: false,
    pathRoute: null,
    form: {
      username: null,
      password: null,
    }
  }),
  watch: {
    $route(val) {
      this.pathRoute = val.name
    }
  },
  created() {
    this.pathRoute = this.$route.name;
  },
  methods: {
    onSubmit() {
      if (this.isEmpty(this.form.username) || this.isEmpty(this.form.password)) {
        return false;
      }
      this.axios.get('login/').then(({ data }) => {
        this.news = data.results;
        // eslint-disable-next-line no-console
        console.log(data.results);
      }).catch(() => {
        this.news = [];
      })
    }
  }
}
</script>